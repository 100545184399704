'use client';

import {
  ComboBox,
  Dropdown,
  Form,
  Modal,
  Stack,
  TextArea,
  TextInput,
} from '@carbon/react';
import { useForm } from 'react-hook-form';

import { AccountSelectItemFragment } from '#fragments';
import { ServiceRequestReason, ServiceRequestType } from '#generated-types';
import { serviceRequestReasons } from './serviceRequestReasons';
import { serviceRequestTypes } from './serviceRequestTypes';

type FormData = {
  accountId: string;
  type: ServiceRequestType;
  reason: ServiceRequestReason;
  subject: string;
  description: string;
};

type AddServiceRequestProps = {
  accounts: AccountSelectItemFragment[];
  onClose: () => void;
  onAdd: (data: FormData) => Promise<void>;
};

export const AddServiceRequest: React.FunctionComponent<
  AddServiceRequestProps
> = ({ accounts, onClose, onAdd }) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      accountId: accounts.length === 1 ? accounts[0].id : undefined,
    },
  });

  const accountId = watch('accountId');
  register('accountId', { required: 'Account is required' });
  register('type', { required: 'Type is required' });
  register('reason', { required: 'Reason is required' });

  return (
    <Modal
      open={true}
      preventCloseOnClickOutside
      modalHeading="Add a new service request"
      primaryButtonText="Save"
      onRequestClose={() => {
        onClose();
      }}
      onRequestSubmit={handleSubmit(async (data: FormData) => {
        await onAdd(data);

        reset();
      })}
    >
      <Form>
        <Stack gap={6}>
          <ComboBox
            id="accountId"
            titleText="Account"
            placeholder="Select account"
            helperText="The account for this service request"
            direction="bottom"
            items={accounts}
            itemToString={(item?: AccountSelectItemFragment) =>
              item?.name ?? ''
            }
            shouldFilterItem={(menu) =>
              menu.item.name
                .toLowerCase()
                .includes(menu.inputValue?.toLowerCase())
            }
            initialSelectedItem={accounts.find((a) => a.id === accountId)}
            onChange={(e) => {
              setValue('accountId', e.selectedItem?.id);
            }}
            invalid={!!errors.accountId}
            invalidText={errors.accountId?.message}
            disabled={accounts.length === 1}
          />

          <Dropdown
            id="type"
            titleText="Type"
            label="Select type"
            helperText="The type of service request"
            direction="bottom"
            items={serviceRequestTypes}
            itemToString={(item?: (typeof serviceRequestTypes)[number]) =>
              item?.name ?? ''
            }
            onChange={(e) => {
              setValue('type', e.selectedItem?.id);
            }}
            invalid={!!errors.type}
            invalidText={errors.type?.message}
          />

          <Dropdown
            id="reason"
            titleText="Reason"
            label="Select reason"
            helperText="The reason for the service request"
            direction="bottom"
            items={serviceRequestReasons}
            itemToString={(item?: (typeof serviceRequestReasons)[number]) =>
              item?.name ?? ''
            }
            onChange={(e) => {
              setValue('reason', e.selectedItem?.id);
            }}
            invalid={!!errors.reason}
            invalidText={errors.reason?.message}
          />

          <TextInput
            {...register('subject', { required: 'Subject is required' })}
            id="subject"
            labelText="Subject"
            enableCounter
            maxCount={255}
            invalid={!!errors.subject}
            invalidText={errors.subject?.message}
          />

          <TextArea
            {...register('description', {
              required: 'Description is required',
            })}
            id="description"
            labelText="Description"
            enableCounter
            maxCount={32000}
            invalid={!!errors.description}
            invalidText={errors.description?.message}
          />
        </Stack>
      </Form>
    </Modal>
  );
};
